<template>
  <div class="top-bar">
    <el-row>
      <el-col class="logo-container">
        <!-- <img @click="backProject" title="点击返回项目列表" :src="logo" class="logo" alt /> -->
        <div @click="backProject">
          <!-- <el-image class="logo" style="width: 130px; height: 60px" :src="logoSrc" fit="contain"></el-image> -->
          <img
            src="../../assets/officialwebsite/logo.png"
            alt=""
            style="width: 130px; height: 60px;cursor: pointer;"
          />
        </div>
      </el-col>
      <el-col class="user">
        <div class="user-middle">
          <p
            v-if="lastTitle == '开发者后台管理系统' || lastTitle == ''"
            style="font-weight: bold;font-size: 18px;"
          >
            {{ lastTitle }}
          </p>
          <p
            v-else
            class="p"
            @click="toRouter(item, index)"
            v-for="(item, index) of title"
            :key="item.router"
          >
            <span
              >{{ item.name
              }}<i
                v-show="index < title.length - 1"
                class="el-icon-arrow-right"
              ></i
            ></span>
          </p>
        </div>
        <div class="userinfo">
          <span class="firstChartStyle">{{ firstChart }}</span>
          <div class="welcome">
            <p>
              <span>{{ userInfoV.nickName }}</span>
            </p>
          </div>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="personMessage">{{
                $t("header.personalInformation")
              }}</el-dropdown-item>
              <el-dropdown-item
                @click.native="toCompanyProject"
                v-if="
                  (spacePermiss.isAdmin ||
                    spacePermiss.isSmartAdmin ||
                    spacePermiss.isSpaceOwner) &&
                    isSpace
                "
                >公司项目</el-dropdown-item
              >
              <el-dropdown-item @click.native="toAdmin(0)">{{
                $t("header.personalAccount")
              }}</el-dropdown-item>
              <el-dropdown-item @click.native="toAdmin(1)">{{
                $t("header.corporateAccount")
              }}</el-dropdown-item>
              <el-dropdown-item @click.native="logout">{{
                $t("header.esc")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div style="width: 14px;"></div>
        <!-- <el-dropdown trigger="click" >
          <div class="el-dropdown-link">文/A：{{ languageText }}</div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="language('zh', '中文')"
              >中文</el-dropdown-item
            >
            <el-dropdown-item @click.native="language('en', 'English')"
              >English</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown> -->
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="passwordDialog"
      :close-on-click-modal="false"
      title="密码修改"
    >
      <el-form
        ref="pasForm"
        label-width="80px"
        label-position="left"
        :model="pwd"
        :rules="rules"
      >
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            v-model="pwd.oldPassword"
            type="password"
            placeholder="旧密码"
          />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="pwd.newPassword"
            placeholder="新密码"
            type="password"
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="surePassword">
          <el-input
            v-model="pwd.surePassword"
            placeholder="确认密码"
            type="password"
          />
        </el-form-item>
      </el-form>
      <div style="text-align:right;">
        <el-button type="danger" @click="passwordDialog = false"
          >取消</el-button
        >
        <el-button type="primary" @click="submitPassword">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Push from "push.js";
import i18n from '.././../language'
var formData = new FormData();
export default {
  name: "TopBar",
  data() {
    return {
      languageText: "中文",
      errorImg: 'this.src="' + require("../../assets/header.png") + '"',
      nickName: "",
      passwordDialog: false,
      editUserDialog: false,
      fileList: [],
      pwd: {
        oldPassword: "",
        newPassword: "",
        surePassword: "",
      },
      PictureUrlService: "",
      rules: {
        oldPassword: [
          {
            required: true,
            message: "请输入旧密码",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        surePassword: [
          {
            required: true,
            message: "请再次输入新密码",
            trigger: "blur",
          },
        ],
      },
      userInfo: JSON.parse(localStorage.getItem("USER_INFO")),
      title: [],
      titleList: [
        {
          name: "所有空间",
          router: "/space",
        },
        {
          name: "项目分类",
          router: "/projectType",
        },
        {
          name: "项目列表",
          router: "/project",
        },
        {
          name: "项目空间",
          router: "/home/table",
        },
      ],
      spaceList: [],
      typeList: [],
      projectList: [],
      activeList: [],
      lastTitle: "",
      firstChart: "",
      logoSrc: require("@/assets/officialwebsite/logo.png"),
      noSpace: false,
      servieceLogo: "",
      currentSpace: {},
      userPermiss: {},
      isSpace: false,
      autoLogo: "https://www.emdataer.com:8028/Doc/123.png",
    };
  },
  computed: {
    ...mapState({
      project: function(state) {
        return state.project;
      },
    }),
    ...mapState("logo", {
      logo: "logo",
    }),
    ...mapState("spacePermiss", {
      spacePermiss: "spacePermiss",
    }),
    ...mapState("user", {
      userInfoV: "userInfo",
    }),
  },
  mounted() {
    this.getHeaderTitle();
    this.nickName = JSON.parse(localStorage.getItem("USER_INFO")).nickName;
    this.firstChart = this.nickName.slice(0, 1);
    let id = localStorage.getItem("eleSpaceId");
    if (!id) {
      return;
    }
    if (this.$route.name != "space" && this.$route.name != "adminSpace" && id) {
      this.$axios.get(`/api/Space/GetSpaceById?SpaceId=${id}`).then((res) => {
        if (res.logoPictureStr) {
          this.initLogo(res.logoPictureStr);
        } else {
          this.initLogo(this.autoLogo);
        }
        this.noSpace = true;
      });
    } else {
      this.initLogo(this.autoLogo);
    }
    this.userPermiss = JSON.parse(localStorage.getItem("userPermiss"));
  },
  created() {
    // 当界面F5刷新后,还原store
    if (localStorage.getItem("userInfo")) {
      // 重新赋值用户信息
      this.initUserInfo(JSON.parse(localStorage.getItem("userInfo")));
      // 赋值还原后清除localStorage的store
      localStorage.removeItem("userInfo");
    }
    let language = sessionStorage.getItem("theLanguage")
      ? sessionStorage.getItem("theLanguage")
      : "zh";
    this.language(language);
  },
  watch: {
    $route(to, from) {
      if (
        to.name === "commoncodenumber" ||
        to.name === "commondataspecification" ||
        to.name === "companycodenumber" ||
        to.name === "companydataspecification" ||
        to.name === "ownProjectType" ||
        to.name === "companyproject"
      ) {
        return;
      }
      this.getHeaderTitle();

      if (to.name != "space" && to.name != "adminSpace") {
        let id = localStorage.getItem("eleSpaceId");
        if (!id) {
          return;
        }
        this.$axios.get(`/api/Space/GetSpaceById?SpaceId=${id}`).then((res) => {
          if (res.logoPictureStr) {
            this.initLogo(res.logoPictureStr);
          } else {
            this.initLogo(this.autoLogo);
          }
          this.noSpace = true;
        });
      } else {
        this.initLogo(this.autoLogo);
      }
      // if (to.name == 'space') {
      // 	this.logoSrc = require('@/assets/officialwebsite/logo.png')
      // 	this.noSpace = false
      // }
    },
  },
  methods: {
    ...mapMutations("action", {
      initAction: "initAction",
    }),
    ...mapMutations("menu", {
      initMenu: "initMenu",
    }),
    ...mapMutations("project", {
      initProject: "initProject",
    }),
    ...mapMutations("project", {
      initSpace: "initSpace",
    }),
    ...mapActions("menu", {
      getMenu: "getMenu",
      clickMenuItem: "clickMenuItem",
    }),
    ...mapMutations("user", {
      initUserInfo: "initUserInfo",
    }),
    ...mapMutations("tableShow", {
      initShowViewId: "initShowViewId",
    }),
    ...mapMutations("spaceInfo", {
      initInSpaceId: "initInSpaceId",
    }),
    ...mapMutations("logo", {
      initLogo: "initLogo",
    }),
    ...mapMutations("spacePermiss", {
      initSpacePermiss: "initSpacePermiss",
    }),

    language(text, languageText = "") {
      let language = sessionStorage.getItem("theLanguage");
      sessionStorage.setItem("theLanguage", text);
      if (text == "zh") {
        this.languageText = "中文";
      } else {
        this.languageText = "English";
      }
      if (text == language) {
        return 0;
      }
      if (languageText) {
        i18n.locale = text
      }
    },
    refresh() {
      location.reload();
    },
    async changSelect(item) {
      let length = this.title.length; // 获取头部长度来判断当前是哪个切换
      if (length == 4) {
        // 等于4的时候是切换项目
        await this.intoProject(item);
        location.reload([true]);
      } else if (length == 3) {
        await this.intoProjectType(item);
        location.reload([true]);
      } else {
        await this.intoProjectSpace(item);
        location.reload([true]);
      }
    },
    intoProjectSpace(space) {
      // 目前默认为
      localStorage.setItem("eleSpaceId", space.id);
      // 保存企业编码

      this.$axios
        .get(`/api/space/getuserpermissionbyspaceid?spaceId=${space.id}`)
        .then((res) => {
          this.authoritySpace(space.id);
          this.initSpace(this.spaceList);
        });
    },
    authoritySpace(id) {
      this.$axios
        .get(`/api/space/getuserpermissionbyspaceid?spaceId=${id}`)
        .then((res) => {
          var roleList = res.permissions;
          var actitons = {};
          for (var item of roleList) {
            actitons[item.permissionDescribe] = true;
          }
          this.initspaceAction(actitons);
        });
    },
    intoProjectType(project) {
      localStorage.setItem("eleProjectTypeId", project.projectTypeId);
    },
    // 权限管理的方法
    authority(id) {
      this.$axios
        .get("/api/Project/GetUserPermissionByprojectId?projectId=" + id)
        .then((res) => {
          var roleList = res.permissions;
          var actitons = {};
          for (var item of roleList) {
            actitons[item.permissionDescribe] = true;
          }
          this.initAction(actitons);
          // 找出所有menu
          let menuList = roleList.filter((item) => {
            return item.permissionType == "Menu";
          });
          menuList = menuList.sort(function(a, b) {
            return a.sort - b.sort;
          });
          // 找出所有父级menu
          let parmenuList = menuList.filter((item2) => {
            return item2.parentID == 0;
          });

          // // 将每个父级的子级存进去
          var menus = [];
          for (let item3 of parmenuList) {
            let obj = {};
            obj.name = item3.permissionName;
            obj.index = item3.permissionDescribe;
            obj.icon = item3.icon;
            obj.includeCom = item3.permissionDescribe;
            let arr = menuList.filter((item4) => {
              return item4.parentID == item3.id;
            });
            if (arr.length != 0) {
              obj.hasChilder = true;
              // obj.children = arr
              obj.children = [];
              for (let child of arr) {
                let objc = {};
                objc.name = child.permissionName;
                objc.index = child.permissionDescribe;
                objc.icon = child.icon;
                objc.includeCom = child.permissionDescribe;
                objc.hasChilder = false;
                obj.children.push(objc);
              }
            } else {
              obj.hasChilder = false;
            }
            menus.push(obj);
          }
          this.initMenu(menus);
        });
    },
    async intoProject(project) {
      await this.$axios
        .get(
          "/api/Project/GetUserPermissionByprojectId?projectId=" +
            project.projectId
        )
        .then((res) => {
          var userMessage = JSON.parse(localStorage.getItem("USER_INFO"));
          userMessage.roles = res.roles;
          localStorage.setItem("USER_INFO", JSON.stringify(userMessage));
        });
      var that = this;
      var spaceId = localStorage.eleSpaceId;
      var projectTypeID = project.projectTypeId;
      var SpaceCode = localStorage.enterpriseCode;
      var loginUserId = JSON.parse(localStorage["USER_INFO"]).userId;
      await this.$db.transaction(function(tx) {
        // 如果表中数据为空，则插入数据
        tx.executeSql(
          "SELECT * FROM ProjectView1",
          [],
          function(tx, res) {
            var haveView = false;
            for (var i = 0; i < res.rows.length; i++) {
              var view = JSON.parse(JSON.stringify(res.rows[i]));
              if (
                (loginUserId == view.UserID) &
                (project.projectId == view.ProjectID)
              ) {
                haveView = true;
              }
            }
            if (haveView) {
              tx.executeSql(
                "DELETE FROM ProjectView1 WHERE ProjectID = ? and UserID = ?",
                [project.projectId, loginUserId]
              );
            }
            var dateTime = that.getFullTime();
            var guid = that.guid();
            var sql =
              "insert into ProjectView1(Guid, SpaceID, ProjectTypeID, ProjectID, UserID, ProjectName,SpaceCode,ProjectCode, DateTime)values('" +
              guid +
              "'," +
              spaceId +
              "," +
              projectTypeID +
              "," +
              project.projectId +
              "," +
              loginUserId +
              ",'" +
              project.name +
              "','" +
              SpaceCode +
              "','" +
              project.projectDescription +
              "','" +
              dateTime +
              "')";
            tx.executeSql(sql);
          },
          function(tx, err) {}
        );
      });
      // 目前默认为
      // GetUserPermissionByprojectId
      localStorage.setItem("projectCode", project.projectDescription);
      localStorage.setItem("eleProjectId", project.projectId);
      localStorage.setItem("projectName", project.name);
      await this.authority(project.projectId);
      // this.initProject(this.projectShowList)
      this.$router.push("/table");
      // 进入项目之后执行权限管理相关操作
    },
    getFullTime() {
      let date = new Date(); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "";
      let M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s;
    },
    guid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0;
        var v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    // 点击头部空间层次信息时
    toRouter(item, index) {
      let routePath = this.$route.path;
      if (routePath == "/home/model") {
        this.$router.push("/home/modellist");
        this.clickMenuItem("modellist");
        return;
      }
      if (
        item.router == "/space" ||
        item.router == "/projectType" ||
        item.router == "/project"
      ) {
        this.clickMenuItem("commonstandards");
      } else if (item.router == "/home/table") {
        this.clickMenuItem("table");
      }
      if (item.router == "/home/table" && routePath == "/home/table") {
        this.initShowViewId(0);
        return;
      }
      this.$router.push(item.router);
    },
    // 判断当前头部显示的信息
    getHeaderTitle() {
      // 判断是否已经进入项目
      // 头部信息的判断
      let routerName = this.$route.name;
      let routePath = this.$route.path;
      if (
        routePath != "/space" &&
        routePath != "/commoncodenumber" &&
        routePath != "/commondataspecification"
      ) {
        this.isSpace = true;
      } else {
        this.isSpace = false;
      }
      if (
        (routerName == "space" ||
          routerName == "adminSpace" ||
          routerName == "ownProjectType" ||
          routerName == "userinfo" ||
          routerName == "companyproject" ||
          routerName == "commoncodenumber" ||
          routerName == "commondataspecification") &&
        routePath != "/home/companydataspecification" &&
        routePath != "/home/companycodenumber" &&
        routePath != "/home/commondataspecification" &&
        routePath != "/home/commoncodenumber"
      ) {
        this.title = this.titleList.slice(0, 1);
        this.lastTitle = this.title[0].name;
      } else if (
        routerName == "projectType" ||
        routerName == "companycodenumber" ||
        routerName == "companydataspecification"
      ) {
        let id = localStorage.getItem("eleSpaceId");
        this.spaceList = this.project.space;
        this.activeList = this.spaceList;
        let find = this.spaceList.find((item) => {
          return item.id == id;
        });
        if (!find.type) {
          this.titleList[1].name = find.spaceOwnerNickName + "的空间";
        } else {
          this.titleList[1].name = find.name;
        }
        this.lastTitle = find.name;
        this.title = this.titleList.slice(0, 2);
      } else if (routerName == "project") {
        this.spaceList = this.project.space;
        this.typeList = this.project.projectType;
        this.activeList = this.typeList;
        let id = localStorage.getItem("eleSpaceId");
        let find = this.spaceList.find((item) => {
          return item.id == id;
        });
        if (!find.type) {
          this.titleList[1].name = find.spaceOwnerNickName + "的空间";
        } else {
          this.titleList[1].name = find.name;
        }
        let idType = localStorage.getItem("eleProjectTypeId");
        let findType = this.typeList.find((item) => {
          return item.projectTypeId == idType;
        });
        this.lastTitle = findType.name;
        this.titleList[2].name = findType.name;
        this.title = this.titleList.slice(0, 3);
      } else {
        this.spaceList = this.project.space;
        this.typeList = this.project.projectType;
        this.projectList = this.project.project;
        this.activeList = this.projectList;
        let id = localStorage.getItem("eleSpaceId");
        let find = this.spaceList.find((item) => {
          return item.id == id;
        });
        if (!find.type) {
          this.titleList[1].name = find.spaceOwnerNickName + "的空间";
        } else {
          this.titleList[1].name = find.name;
        }
        // 分类
        let idType = localStorage.getItem("eleProjectTypeId");
        let findType = this.typeList.find((item) => {
          return item.projectTypeId == idType;
        });
        this.titleList[2].name = findType.name;
        this.titleList[3].name = localStorage.getItem("projectName");
        this.lastTitle = localStorage.getItem("projectName");
        this.title = this.titleList;
      }
    },
    pushMessage(data) {
      Push.create(data.Title, {
        body: data.MessageContent,
        requireInteraction: true,
        icon: require("@/assets/message.png"),
        timeout: 600000,
      });
    },
    personMessage() {
      this.$router.push("/userinfo");
    },
    updatePwd() {
      this.passwordDialog = true;
    },
    submitPassword() {
      this.$refs.pasForm.validate(async (valid) => {
        if (valid) {
          let user = JSON.parse(localStorage.getItem("USER_INFO"));
          let localPassword = user.password;
          if (localPassword == this.pwd.newPassword)
            return this.$message.warning("新密码和旧密码不能一致");
          if (this.pwd.newPassword !== this.pwd.surePassword)
            return this.$message.warning("两次密码输入不同，请重新输入");
          let res = await this.$axios.get(
            `/api/user/updateuserpassword?userId=${user.userId}&password=${
              this.pwd.newPassword
            }&oldPassword=${this.pwd.oldPassword}`
          );
          if (res == "修改密码成功") {
            this.passwordDialog = false;
            this.$alert("密码已修改，须重新登录", "退出登录", {
              confirmButtonText: "确定",
              callback: () => {
                var userName = localStorage.getItem("userName");
                localStorage.clear();
                localStorage.setItem("userName", userName);
                this.$router.push("/login");
              },
            });
          }
        } else {
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.userInfo.PictureUrlService = URL.createObjectURL(file.raw);
    },
    uploadPicture(file) {
      formData.append("FormFile", file.file);
    },
    getPicture(file, fileList) {
      this.PictureUrlService = file.url;
    },
    logout() {
      var userName = localStorage.getItem("userName");
      var password = localStorage.getItem("passWord");
      localStorage.clear();
      this.$router.push("/login");
      localStorage.setItem("userName", userName);
      localStorage.setItem("passWord", password);
    },

    backProject() {
      this.$router.push("/index");
    },
    setUserInfo(sid) {
      this.$axios
        .get(
          `/api/project/judgeuserpermiss?SpaceId=${sid}&UserId=${localStorage.getItem(
            "USER_ID"
          )}`
        )
        .then((res) => {
          this.userPermiss = res;
          this.initSpacePermiss(res);
        });
    },
    async toAdmin(data) {
      // 个人
      if (!data) {
        let id = this.$store.state.spaceInfo.personalSpaceId;
        if (!id) {
          this.$message.error("请先购买您的个人空间");
          return;
        } else {
          await this.setUserInfo(id);
          await this.initInSpaceId(id);
        }
      } else {
        let id = this.$store.state.spaceInfo.companySpaceId;
        if (!id) {
          this.$message.error("请先加入或购买企业");
          return;
        } else {
          await this.setUserInfo(id);
          await this.initInSpaceId(id);
        }
      }

      // return
      if (this.$route.name == "ownProjectType") {
        location.reload([true]);
      } else {
        this.$router.push("/ownProjectType");
      }

      // })
    },
    toCompanyProject() {
      if (localStorage.getItem("eleSpaceId")) {
        this.$router.push("/companyproject");
      } else {
        this.$message.error("请先选择公司");
      }
    },
  },
};
</script>

<style>
.top-bar {
  height: 100%;
  box-shadow: 0px -2px 22px 0px rgba(217, 217, 217, 0.5);
  padding: 0 2%;
  /* background-color: #000000; */
}

.firstChartStyle {
  display: inline-block;
  background-color: #475065;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
}

.el-dropdown-link {
  cursor: pointer;
}
/* .top-bar .el-popover .el-popper div:nth-child(1){
		width: 50px !important;
	} */
/* .el-dropdown{
		height: 20px;
	} */
.logo-container {
  /* position: relative; */
  /* background-color: #475065; */
  width: 130px;
  height: 60px;
  /* position: relative;
		left: 2%; */
  /* margin-left: 10px; */
}

/* .logo-container img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	} */

.logo {
  /* height: 60px;
		width: 130px;
		margin-right: 5px;
		vertical-align: middle;
		display: inline-block; */
  cursor: pointer;
}

/* .logoAuto{
		height: 38px;
		width: 110px;
		margin-right: 5px;
		vertical-align: middle;
		display: inline-block;
		cursor: pointer;
	} */
.title {
  vertical-align: middle;
  font-size: 22px;
  font-family: "Microsoft YaHei";  
}

.user {
  display: flex;
  justify-content: space-between;
  /* background-color: #000000; */
  line-height: 60px;
  text-align: right;
  float: right;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
  height: 60px !important;
  /* max-width: calc(100vw - 130px); */
  /* min-width: calc(100vw - 150px); */
  /* width: 1850px; */
  width: calc(100% - 140px);
}

.user-middle {
  position: relative;
  display: flex;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  /* font-weight: 600; */
  color: #343434;
  /* background-color: #000000; */
}

.user-middle > p {
  position: relative;
  margin-left: 10px;
  cursor: pointer;
}

.p {
  position: relative;
  /* max-width: 110px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap; */
}

.bottom-box {
  position: absolute;
  bottom: 5px;
  /* right: 10px; */
  display: block;
  width: 80%;
  height: 6px;
  left: 50%;
  transform: translate(-50%);
  background: #02a7f0;
  bottom: 10px;
}

/* .p:last-child::after{
		display: block;
		content: "";
		position: absolute;
		width: 80%;
		height: 6px;
		left: 50%;
		transform: translate(-50%);
		background: #02A7F0;
		bottom: 10px;
	} */
/* .p span{
		position: absolute;
		z-index: 9999;
		display: inline-block;
		width: 50px;
		height: 5px;
		background-color: #000000;
		top: 50px;
	} */
/* .p:last-child{
		font-weight: 600;
	}
	.p:last-child::after{
		display: block;
		content: "";
		position: absolute;
		width: 80%;
		height: 6px;
		left: 50%;
		transform: translate(-50%);
		background: #02A7F0;
		bottom: 10px;
	} */
.userinfo {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
}

.avatarPic {
  width: 146px;
  height: 146px;
  border-radius: 5px;
  vertical-align: middle;
  display: inline-block;
}

.name {
  line-height: 20px;
  text-align: center;
  font-size: 14px;
}

.welcome {
  display: inline-block;
  width: auto;
  vertical-align: middle;
  padding: 0 5px;
}

.avatarname {
  color: #409eff;
  font-weight: bolder;
}

.username {
  cursor: pointer;
  margin-right: 5px;
}

.notice {
  position: relative;
  margin: 0 20px;
  font-size: 20px;
}

.notice-num {
  position: absolute;
  top: 12px;
  left: 12px;
  display: inline-block;
  padding: 0 7px;
  /* height: 0; */
  height: 17px;
  line-height: 17px;
  border-radius: 8px;
  background: linear-gradient(135deg, #f38282 0%, #e35f4b 100%);
  font-size: 12px;
  color: #ffffff;
}
</style>
